.App {
  text-align: center;
}

.App-header {
  background-color: #282c34;
  padding: 20px;
  color: white;
}

button {
  margin: 10px;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  border: none;
  border-radius: 5px;
  background-color: #61dafb;
  color: #282c34;
  transition: background-color 0.3s;
}

button:hover {
  background-color: #21a1f1;
}

.App-section {
  padding: 20px;
  margin: 20px;
  border: 1px solid #ddd;
}

img {
  max-width: 90%;
  height: auto;
  margin: 20px 0;
}

a {
  text-decoration: none;
  color: inherit;
}

@media (max-width: 768px) {
  .App-header {
    padding: 10px;
  }

  button {
    margin: 5px;
    padding: 8px 16px;
    font-size: 14px;
  }

  img {
    max-width: 100%;
    margin: 10px 0;
  }

  .App-section {
    padding: 10px;
    margin: 10px;
  }
}
